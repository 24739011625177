<template>
  <b-row>
    <b-col>
      <b-button-toolbar justify>
        <b-button-group
          class="mr-2 mb-1"
          vertical
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :to="{name: 'new-location'}"
            variant="outline-primary"
          >
            <feather-icon
              class="mr-1"
              icon="PlusCircleIcon"
            />
            Add Campus
          </b-button>
        </b-button-group>
      </b-button-toolbar>
      <b-row>
        <b-col
          v-for="(location, index) in getLocations"
          :key="index"
          md="6"
          xl="4"
          lg="6"
        >
          <b-link :to="{ name: 'edit-location', params: { locationId: location.id }}">
            <b-card
              key="{{ location.id }}"
              md="6"
              :img-src="require('@/assets/images/people4.png')"
            >
              <b-row>
                <b-col>
                  <b-media vertical-align="top">
                    <template #aside>
                      <b-avatar
                        variant="light-primary"
                        square
                        size="lg"
                      >
                        <feather-icon icon="HomeIcon" />
                      </b-avatar>
                    </template>
                    <h4 class="media-heading">
                      {{ location.name }}
                    </h4>
                    <b-card-text class="mb-0">
                      Address: {{ location.address }}
                    </b-card-text>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
          </b-link>
        </b-col>
        <b-col
          md="6"
          xl="4"
          lg="6"
        >
          <b-link :to="{name: 'new-location'}">
            <b-card
              md="6"
              :img-src="require('@/assets/images/add.png')"
              class="shadow-none bg-transparent border-secondary add-campus-card"
            >
              <b-row>
                <b-col md="12">
                  <b-media vertical-align="top">
                    <h4 class="media-heading">
                      Add Campus
                    </h4>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
          </b-link>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import {
  BAvatar,
  BButton,
  BButtonGroup,
  BButtonToolbar,
  BCard,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BLink,
  BMedia,
  BRow,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
    BCardText,
    BLink,
    BButtonToolbar,
    BButtonGroup,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  computed: {
    ...mapGetters({ getLocations: 'auth/locations' }),
  },
  directives: {
    Ripple,
  },
}
</script>

<style scoped>
  [dir] .border-secondary {
    border: 1px dashed #c7c7c7 !important;
  }

  [dir] .border-secondary:hover {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
  }

  [dir] .card:hover {
    box-shadow: 0 4px 74px 0 rgb(34 41 47 / 15%) !important;
  }
  .card .card-img{
    margin-top:-1px;
    background-color: #f5f5f5;
    max-height:390px;
  }
  .dark-layout .card .card-img{
    background-color: #131821;
  }
  .card.bg-transparent .card-img{
    margin-top:0;
  }
  .dark-layout .border-secondary {
    border: 1px dashed var(--purple) !important;
  }
  .dark-layout .border-secondary img{
    filter: invert(1);
    opacity: 0.5;
  }
  .add-campus-card .card-body{
    height:91px;
    display:flex;
    align-items:center;
  }
  .add-campus-card .card-body h4{
    margin-bottom:0;
  }
</style>
